import { MsGraphUser } from "common/types";

// If the user has any of the provided roles this will return true
export const isUserAuthorized = (
  userInfo: MsGraphUser | null,
  ...allowedRoles: string[]
): boolean => {
  return userInfo
    ? allowedRoles.some((role) => userInfo.roles.includes(role))
    : false;
};
