import styled from "styled-components";
import { Calendar, Flex } from "antd";

export const StyledCalendar = styled(Calendar)`
  border-radius: 0.4rem;
  padding: 0.1rem 0.4rem 0.2rem 0.4rem;
  height: 30.5rem;
`;

export const StyledCalendarTableRow = styled(Flex)`
  flex-direction: row;
  flex-grow: 1;
  gap: 1rem;
`;
