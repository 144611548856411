import { theme } from "antd";

export const getColourFromNumber = (id: number) => {
  const colors = [
    "#00ffcc",
    "#ff0080",
    "#ffcc00",
    "#4a0080",
    "#ff6600",
    "#66ff00",
    "#0066ff",
    "#ff0066",
    "#6600ff",
    "#ff0033",
  ];
  return colors[id % colors.length];
};

export const themeConfig = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorPrimary: "#c80000",
    fontSize: 16,
    fontSizeHeading1: 32,
    fontSizeHeading2: 20.8,
    fontWeightStrong: 600,
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
    },
    Layout: {
      headerBg: "#0e0d0d",
      colorText: "white",
      siderBg: "#181818",
      bodyBg: "#212121",
      headerHeight: "4.5rem",
      headerPadding: "0.6rem var(--content-padding)",
    },
    Menu: {
      colorPrimary: "white",
      activeBarHeight: 0,
      activeBarBorderWidth: 0,
      horizontalItemSelectedBg: "#444444",
      horizontalItemHoverBg: "#333333",
      horizontalItemBorderRadius: 8,
      popupBg: "#333333",
      itemSelectedBg: "#555555",
      itemHeight: "2.3rem",
      subMenuItemBorderRadius: 8,
      subMenuItemBg: "transparent",
    },
    Divider: {
      margin: 0,
      marginLG: 0,
    },
    Modal: {
      contentBg: "#292929",
      headerBg: "#292929",
    },
  },
};
