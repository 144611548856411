import React from "react";
import { Button, Drawer } from "antd";
import { StyledMenu } from "common/components/styled";
import { State } from "common/types";
import { sidebar } from "common/enums";
import { ItemType } from "antd/es/menu/interface";

export const SideDrawerMenu = (props: {
  page: State<string>;
  open: State<boolean>;
  icon: JSX.Element;
  menuItems: ItemType[];
}) => {
  const isDataManagementChildSelected = sidebar.some(
    (item) => item.key === props.page.get
  );

  return (
    <>
      <Button
        type="text"
        icon={props.icon}
        onClick={() => props.open.set(true)}
        size="large"
      />
      <Drawer
        closable
        placement="left"
        onClose={() => props.open.set(false)}
        open={props.open.get}
        width="20rem"
        styles={{
          header: {
            minHeight: "var(--navbar-height)",
          },
          body: {
            padding: "0",
          },
        }}
      >
        <StyledMenu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["dataManagement"]}
          selectable
          selectedKeys={[props.page.get]}
          defaultOpenKeys={
            isDataManagementChildSelected ? ["dataManagement"] : []
          }
          items={props.menuItems}
          style={{ margin: 0, fontSize: "1.5rem" }}
        />
      </Drawer>
    </>
  );
};
