export enum formInputType {
  text,
  paragraph,
  number,
  integer,
  date,
  dateRange,
  dropdown,
  checkbox,
}
