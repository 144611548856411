import React from "react";
import { StyledMenu } from "common/components/styled";
import { State } from "common/types";
import { ItemType } from "antd/es/menu/interface";

export const HorizontalMenu = (props: {
  page: State<string>;
  menuItems: ItemType[];
}) => {
  return (
    <StyledMenu
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={["dataManagement"]}
      selectable
      selectedKeys={[props.page.get]}
      items={props.menuItems}
    />
  );
};
