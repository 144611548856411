import React, { useEffect, useState } from "react";
import { Col, Divider, Modal, Table, Typography } from "antd";
import { useAPI } from "common/hooks";
import {
  GeneralUnavailability,
  StandbyPlusUserNames,
  State,
} from "common/types";
import dayjs, { Dayjs } from "dayjs";
import { Center, TableChin } from "common/components/styled";
import { WarningOutlined } from "@ant-design/icons";

export const DaySchedule = (props: { date: Dayjs; isOpen: State<boolean> }) => {
  const [unavailabilities, setUnavailabilities] = useState<
    GeneralUnavailability[]
  >([]);
  const [standbys, setStandbys] = useState<StandbyPlusUserNames[]>([]);

  const { Title } = Typography;
  const api = useAPI();

  useEffect(() => {
    const getData = async () => {
      const dayStandbys = await api.get(
        `/standby/day?date=${props.date.toISOString()}`
      );
      setStandbys(dayStandbys);

      const dayUnavailabilities = await api.get(
        `/unavailability/day?date=${props.date.toISOString()}`
      );
      setUnavailabilities(dayUnavailabilities);
    };

    getData();
  }, [api, props.date]);

  const sortedStandbys = [...standbys].sort(
    (a, b) => new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
  );

  const sortedUnavailabilities = [...unavailabilities].sort(
    (a, b) => new Date(a.StartDate).getTime() - new Date(b.StartDate).getTime()
  );

  const dayStart = new Date(props.date.toDate());
  dayStart.setHours(0, 0, 0, 0);
  const dayEnd = new Date(props.date.toDate());
  dayEnd.setHours(23, 59, 59, 999);

  let prevTime = dayStart;
  let gaps: { StartTime: Date; EndTime: Date }[] = [];

  standbys.forEach((standby, index) => {
    const startTime = new Date(standby.StartDate);
    // Cut off start time to be the start of the day at the earliest
    if (startTime.getTime() < dayStart.getTime()) {
      startTime.setTime(dayStart.getTime());
    }
    const endTime = new Date(standby.EndDate);
    // Cut off end time to be the end of the day at the latest
    if (endTime.getTime() > dayEnd.getTime()) {
      endTime.setTime(dayEnd.getTime());
    }

    if (prevTime.getTime() !== startTime.getTime()) {
      gaps.push({ StartTime: prevTime, EndTime: startTime });
    }
    prevTime = new Date(endTime);

    if (index === standbys.length - 1) {
      if (endTime.getTime() !== dayEnd.getTime()) {
        gaps.push({ StartTime: endTime, EndTime: dayEnd });
      }
    }
  });

  return (
    <Modal
      open={props.isOpen.get}
      onCancel={() => {
        props.isOpen.set(false);
      }}
      footer={[]}
    >
      <Title level={3}>
        Detailed info for {props.date.toDate().toLocaleDateString()}
      </Title>
      <Divider />
      {gaps.length !== 0 && (
        <>
          <Title level={2} style={{ paddingTop: "1rem", color: "yellow" }}>
            <WarningOutlined
              style={{ color: "yellow", marginRight: "0.5rem" }}
            />
            Warning, Gaps in Standby schedule:
          </Title>
          <Col style={{ maxHeight: "7.5rem", overflow: "scroll" }}>
            <TableChin>
              <Table
                columns={[
                  { title: "From", dataIndex: "StartTime" },
                  { title: "To", dataIndex: "EndTime" },
                ]}
                dataSource={gaps.map((gap) => ({
                  key: gap.StartTime,
                  StartTime: dayjs(gap.StartTime).format("HH:mm:ss"),
                  EndTime: dayjs(gap.EndTime).format("HH:mm:ss"),
                }))}
                size="small"
                pagination={false}
                style={{ width: "100%" }}
              />
            </TableChin>
          </Col>
        </>
      )}
      <Title level={2} style={{ paddingTop: "1rem" }}>
        Standby:
      </Title>
      <Col style={{ maxHeight: "19rem", overflow: "scroll" }}>
        {sortedStandbys.map((standby, index) => {
          let truncatedFromDate = new Date(standby.StartDate);
          const startOfDay = props.date.toDate();
          startOfDay.setHours(0, 0, 0, 0);
          if (truncatedFromDate < startOfDay) {
            truncatedFromDate = startOfDay;
          }

          let truncatedToDate = new Date(standby.EndDate);
          const endOfDay = props.date.toDate();
          endOfDay.setHours(23, 59, 59, 999);
          if (truncatedToDate > endOfDay) {
            truncatedToDate = endOfDay;
          }

          return (
            <TableChin key={standby.StandbyID}>
              <Table
                columns={[
                  {
                    title: `${dayjs(truncatedFromDate).format("HH:mm:ss")} to
                  ${dayjs(truncatedToDate).format("HH:mm:ss")}`,
                    dataIndex: "assignment",
                  },
                  { title: "Assignee", dataIndex: "name" },
                ]}
                dataSource={[
                  {
                    key: 1,
                    assignment: "Primary Standby",
                    name: standby.PrimaryUser.Name,
                  },
                  {
                    key: 2,
                    assignment: "Secondary Standby",
                    name: standby.SecondaryUser.Name,
                  },
                  {
                    key: 3,
                    assignment: "Primary Escalation",
                    name: standby.PrimaryEscalationUser.Name,
                  },
                  {
                    key: 4,
                    assignment: "Secondary Escalation",
                    name: standby.SecondaryEscalationUser.Name,
                  },
                  {
                    key: 5,
                    assignment: "Final Escalation",
                    name: standby.FinalEscalationUser.Name,
                  },
                ]}
                size="small"
                pagination={false}
                style={{
                  borderRadius: "8px",
                  width: "100%",
                }}
              />
            </TableChin>
          );
        })}
      </Col>
      {standbys.length === 0 && <Center>No Standby schedules set</Center>}
      <Title level={2} style={{ marginTop: "1rem" }}>
        Unavailabilities:
      </Title>
      {unavailabilities.length !== 0 && (
        <Col style={{ maxHeight: "11rem", overflow: "scroll" }}>
          <TableChin>
            <Table
              columns={[
                { title: "User", dataIndex: "user" },
                { title: "Start Date", dataIndex: "startDate" },
                { title: "End Date", dataIndex: "endDate" },
              ]}
              dataSource={sortedUnavailabilities.map((unavailability) => ({
                key: unavailability.ID,
                user: unavailability.MservUser.Name,
                startDate: dayjs(unavailability.StartDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                endDate: dayjs(unavailability.EndDate).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
              }))}
              size="small"
              pagination={false}
              style={{ width: "100%" }}
            />
          </TableChin>
        </Col>
      )}
      {unavailabilities.length === 0 && (
        <Center>No Unavailabilities set</Center>
      )}
    </Modal>
  );
};
