import React from "react";
import { Flex } from "antd";
import { Logo } from "common/components/styled";
import { images } from "common/enums";

export const CloudLogo = (props: { onClick: () => void; width: string }) => {
  return (
    <Flex style={{ flexGrow: 0, width: props.width }}>
      <Logo
        src={images.logo}
        preview={false}
        onClick={props.onClick}
        style={{
          objectFit: "contain",
        }}
      />
    </Flex>
  );
};
