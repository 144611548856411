import React, { useContext, useState } from "react";
import { Avatar, MenuProps, Dropdown, Typography } from "antd";
import { Nav } from "common/components/styled";
import { useMobile } from "common/hooks";
import { sidebar } from "common/enums/sidebar";
import { useNavigate } from "react-router-dom";
import { State } from "common/types";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { UserContext } from "common/context";
import { MenuOutlined } from "@ant-design/icons";
import {
  CloudLogo,
  HorizontalMenu,
  LoadingSpinner,
  SideDrawerMenu,
} from "common/components";

const { Title } = Typography;

export const Navbar = (props: { page: State<string> }) => {
  const isAuthenticated = useIsAuthenticated();
  const isMobile = useMobile();
  const navigate = useNavigate();
  const userInfo = useContext(UserContext);
  const { instance } = useMsal();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const goHome = () => {
    navigate("/");
    props.page.set("");
  };

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/login",
    });
  };

  const navbarItems: MenuProps["items"] = [
    {
      key: "dataManagement",
      label: "Data Management",
      children: sidebar.map(({ path, ...items }) => items),
      popupOffset: [-14.5, 0],
      onClick: (e) => {
        props.page.set(e.key);
        const pageObject = sidebar.find((item) => item.key === e.key);
        if (pageObject !== undefined) {
          navigate(pageObject.path);
        }
        setIsDrawerOpen(false);
      },
    },
    {
      key: "standby",
      label: "Standby",
      onClick: (e) => {
        props.page.set(e.key);
        navigate("/standby");
        setIsDrawerOpen(false);
      },
    },
    {
      key: "unavailability",
      label: "Unavailability",
      onClick: (e) => {
        props.page.set(e.key);
        navigate("/unavailability");
        setIsDrawerOpen(false);
      },
    },
    {
      key: "contractHours",
      label: "Hours Usage",
      onClick: (e) => {
        props.page.set(e.key);
        navigate("/contract-hours");
        setIsDrawerOpen(false);
      },
    },
  ];

  return (
    <Nav>
      {isAuthenticated && (
        <>
          {isMobile ? (
            <>
              <SideDrawerMenu
                page={props.page}
                open={{ get: isDrawerOpen, set: setIsDrawerOpen }}
                icon={<MenuOutlined />}
                menuItems={navbarItems}
              />
              <CloudLogo onClick={goHome} width="7rem" />
            </>
          ) : (
            <>
              <CloudLogo onClick={goHome} width="7rem" />
              <HorizontalMenu page={props.page} menuItems={navbarItems} />
            </>
          )}
          {userInfo ? (
            <>
              {!isMobile && <Title level={2}>{userInfo.displayName}</Title>}
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "logout",
                      label: "Logout",
                      onClick: () => {
                        handleLogout();
                      },
                    },
                  ],
                }}
                trigger={["click"]}
              >
                {userInfo.profilePhoto ? (
                  <Avatar
                    src={userInfo.profilePhoto}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <Avatar style={{ cursor: "pointer" }}>
                    {userInfo.givenName.charAt(0)}
                    {userInfo.surname.charAt(0)}
                  </Avatar>
                )}
              </Dropdown>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </>
      )}
    </Nav>
  );
};
