import { Checkbox, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { formInputType } from "common/enums";
import { FormItem } from "common/types";
import dayjs from "dayjs";

const { Item } = Form;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

export const FormField = <T extends object>({
  formItem,
}: {
  formItem: FormItem<T>;
}) => {
  let minDate: dayjs.Dayjs | undefined = undefined;
  let maxDate: dayjs.Dayjs | undefined = undefined;
  if (formItem.minDate) {
    minDate = dayjs(formItem.minDate);
  }
  if (formItem.maxDate) {
    maxDate = dayjs(formItem.maxDate);
  }

  let clearableDropdown = true;
  if (formItem.rules) {
    if (formItem.rules.length > 0) {
      if ("required" in formItem.rules[0]) {
        clearableDropdown = !formItem.rules[0]["required"];
      }
    }
  }

  return (
    <Item
      key={formItem.name as string}
      name={formItem.name as string}
      rules={formItem.rules}
      label={
        formItem.inputType !== formInputType.checkbox
          ? formItem.label
          : undefined
      }
      valuePropName={
        formItem.inputType === formInputType.checkbox ? "checked" : undefined
      }
      hidden={formItem.hidden}
    >
      {formItem.inputType === formInputType.dropdown &&
      formItem.dropdownOptions ? (
        <Select
          disabled={formItem.disabled}
          style={{ width: "100%" }}
          allowClear={clearableDropdown}
        >
          {formItem.dropdownOptions.map((option) => (
            <Option key={option.label} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      ) : formItem.inputType === formInputType.checkbox ? (
        <Checkbox disabled={formItem.disabled}>{formItem.label}</Checkbox>
      ) : formItem.inputType === formInputType.date ? (
        <DatePicker
          disabled={formItem.disabled}
          style={{ width: "100%" }}
          disabledDate={(current) => {
            return (
              ((minDate ?? false) && current.isBefore(minDate)) ||
              ((maxDate ?? false) && current.isAfter(maxDate))
            );
          }}
          showTime={formItem.showDateTime ?? false}
        />
      ) : formItem.inputType === formInputType.dateRange ? (
        <RangePicker
          disabled={formItem.disabled}
          style={{ width: "100%" }}
          disabledDate={(current) => {
            return (
              ((minDate ?? false) && current.isBefore(minDate)) ||
              ((maxDate ?? false) && current.isAfter(maxDate))
            );
          }}
          allowEmpty={[true, true]}
          showTime={formItem.showDateTime ?? false}
        />
      ) : formItem.inputType === formInputType.number ? (
        <InputNumber disabled={formItem.disabled} style={{ width: "100%" }} />
      ) : formItem.inputType === formInputType.integer ? (
        <InputNumber
          disabled={formItem.disabled}
          precision={0}
          style={{ width: "100%" }}
        />
      ) : formItem.inputType === formInputType.paragraph ? (
        <TextArea disabled={formItem.disabled} rows={4} />
      ) : (
        <Input disabled={formItem.disabled} type="text" />
      )}
    </Item>
  );
};
