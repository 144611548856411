import React, { useEffect, useState } from "react";
import { FormItem, TableProps } from "common/types";
import { Button, Flex, Popover, Table } from "antd";
import {
  DeleteEntryButton,
  DeleteEntryPopconfirm,
  InsertEntryButton,
  TableContainer,
} from "common/components/styled";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { GenericForm } from "common/components";
import { SortOrder } from "antd/es/table/interface";

export const GenericCrudTable = <
  TableType extends object,
  FormType extends object
>({
  idKey,
  form,
  columns,
  dataSource,
  pagination,
  loading,
  sticky,
  scroll,
  size,
  width,
  formTitleIcon,
  getFormItems,
  createConfig,
  updateConfig,
  deleteConfig,
}: TableProps<TableType, FormType>): JSX.Element => {
  const [formTitle, setFormTitle] = useState<string>("");
  const [formItems, setFormItems] = useState<Array<FormItem<FormType>>>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [tableHeight, setTableHeight] = useState(100);
  const [cellHeight, setCellHeight] = useState(100);
  const [editID, setEditID] = useState<number | null>(null);

  useEffect(() => {
    const resizeObserverTable = new ResizeObserver((event) => {
      setTableHeight(event[0].contentBoxSize[0].blockSize);
    });
    const resizeObserverCell = new ResizeObserver((event) => {
      setCellHeight(event[0].contentBoxSize[0].blockSize);
    });

    resizeObserverTable.observe(
      document.getElementById("tableContainer") as Element
    );
    resizeObserverCell.observe(
      document.getElementsByClassName("ant-table-cell")[0] as Element
    );
  }, []);

  const handleFormOpen = async (title: string, entity?: TableType) => {
    let formItems = [];
    if (entity !== undefined) {
      formItems = getFormItems("update", entity);
      setEditID(Number(entity[idKey]));
    } else {
      formItems = getFormItems("create");
      setEditID(null);
    }
    setFormItems(formItems);
    setFormTitle(title);
    setIsFormVisible(true);
  };

  const handleFormClose = () => {
    setIsFormVisible(false);
  };

  const handleFormSubmit = async (entity: FormType) => {
    if (editID) {
      await updateConfig.action(entity, editID);
    } else {
      await createConfig.action(entity);
    }
    handleFormClose();
  };

  return (
    <TableContainer id="tableContainer" style={{ width: width || "100%" }}>
      <GenericForm<FormType>
        form={form}
        titleIcon={formTitleIcon}
        title={formTitle}
        formItems={formItems}
        visible={isFormVisible}
        onCancel={handleFormClose}
        onSubmit={handleFormSubmit}
      />
      <Table<TableType>
        columns={[
          ...columns.map((item) => {
            return {
              ...item,
              key: String(item.dataIndex),
              title: item.title,
              dataIndex: String(item.dataIndex),
              filters: item.filters,
              onFilter:
                item.onFilter || item.filters
                  ? (value: boolean | React.Key, record: any) => {
                      const renderedValue = item.render
                        ? item.render(record[item.dataIndex], record, 0)
                        : String(record[item.dataIndex]);
                      return String(renderedValue).includes(String(value));
                    }
                  : undefined,
              sortDirections:
                item.sortDirections ||
                (["ascend", "descend", undefined] as SortOrder[]),
              defaultSortOrder:
                item.defaultSortOrder || item.dataIndex === idKey
                  ? ("ascend" as SortOrder)
                  : undefined,
              sorter:
                item.sorter === true
                  ? (a: any, b: any) => {
                      const aValue = item.render
                        ? item.render(a[item.dataIndex], a, 0)
                        : a[item.dataIndex];
                      const bValue = item.render
                        ? item.render(b[item.dataIndex], b, 0)
                        : b[item.dataIndex];
                      if (
                        typeof aValue === "number" &&
                        typeof bValue === "number"
                      ) {
                        return aValue - bValue;
                      } else {
                        return String(aValue).localeCompare(String(bValue));
                      }
                    }
                  : item.sorter,
              width:
                item.dataIndex === idKey && !item.width ? "3.5rem" : item.width,
              fixed: item.dataIndex === idKey ? "left" : item.fixed,
            };
          }),
          ...[
            {
              title: (
                <Popover
                  content={
                    createConfig.disabled
                      ? createConfig.disabledMessage ||
                        "Insufficient permissions"
                      : "Insert new entry"
                  }
                  trigger="hover"
                >
                  <InsertEntryButton
                    icon={<PlusOutlined style={{ fontSize: 20 }} />}
                    disabled={createConfig.disabled}
                    onClick={async () => {
                      await handleFormOpen("Create entry");
                    }}
                    type="primary"
                  />
                </Popover>
              ),
              key: "form-actions",
              render: (record: TableType) => (
                <Flex justify="flex-end">
                  <Popover
                    content={() => {
                      if (updateConfig.disabled(record)) {
                        return (
                          updateConfig.disabledMessage?.(record) ||
                          "Insufficient permissions"
                        );
                      } else {
                        return "Edit entry";
                      }
                    }}
                    trigger="hover"
                  >
                    <Button
                      icon={<EditOutlined />}
                      disabled={updateConfig.disabled(record)}
                      onClick={async () =>
                        await handleFormOpen("Edit entry", record)
                      }
                    />
                  </Popover>
                  <DeleteEntryPopconfirm
                    title="Delete the entry"
                    description={`Are you sure to delete this entry?`}
                    disabled={deleteConfig.disabled(record)}
                    onConfirm={async () => {
                      await deleteConfig.action(Number(record[idKey]));
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Popover
                      content={() => {
                        if (deleteConfig.disabled(record)) {
                          return (
                            deleteConfig.disabledMessage?.(record) ||
                            "Insufficient permissions"
                          );
                        } else {
                          return "Delete entry";
                        }
                      }}
                      trigger="hover"
                    >
                      <DeleteEntryButton
                        disabled={deleteConfig.disabled(record)}
                        icon={<DeleteOutlined />}
                      />
                    </Popover>
                  </DeleteEntryPopconfirm>
                </Flex>
              ),
              width: "5rem",
              align: "right" as "left" | "right" | "center" | undefined,
              fixed: "right" as "left" | "right" | undefined,
            },
          ],
        ]}
        dataSource={dataSource}
        pagination={
          pagination ?? {
            showSizeChanger: true,
            pageSizeOptions: ["8", "12", "16", "32", "64", "128"],
            defaultPageSize: 8,
            showTotal: (total, range) => {
              return `${range[0]}-${range[1]} of ${total} items`;
            },
          }
        }
        loading={loading ?? false}
        sticky={sticky ?? true}
        scroll={scroll ?? { y: tableHeight - 3.5 * cellHeight }}
        size={size ?? "small"}
        style={{ width: "100%" }}
      />
    </TableContainer>
  );
};
