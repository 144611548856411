import React, { createContext, ReactNode, useEffect, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest, tokenRequest, graphConfig } from "authConfig";
import { MsGraphUser } from "common/types";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";

export const UserContext = createContext<MsGraphUser | null>(null);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<MsGraphUser | null>(null);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const account = accounts[0];
        if (account) {
          const loginTokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
          });
          const apiTokenResponse = await instance.acquireTokenSilent({
            ...tokenRequest,
            account,
          });

          // Decode the API access token to get the roles
          const decodedToken: any = jwtDecode(apiTokenResponse.accessToken);
          const roles = decodedToken.roles || [];

          // Use the graph API token to fetch the user's profile
          const userProfileResponse = await fetch(graphConfig.graphMeEndpoint, {
            headers: {
              Authorization: `Bearer ${loginTokenResponse.accessToken}`,
            },
          }).then((res) => res.json());

          // Use the graph API token to fetch the user's profile photo
          const photoResponse = await fetch(
            `${graphConfig.graphMeEndpoint}/photo/$value`,
            {
              headers: {
                Authorization: `Bearer ${loginTokenResponse.accessToken}`,
              },
            }
          );
          const photoBlob = photoResponse.ok
            ? await photoResponse.blob()
            : null;

          setUser({
            ...userProfileResponse,
            profilePhoto: photoBlob ? URL.createObjectURL(photoBlob) : null,
            roles,
          });
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest);
        } else {
          console.error("User profile acquisition failed:", error);
        }
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    }
  }, [accounts, instance, isAuthenticated]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
