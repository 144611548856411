export const handleResponse = (
  response: {
    ok: boolean;
    status: number;
    json: any;
  },
  failureMessage: string
) => {
  if (!response.ok) {
    alert(
      `${failureMessage}\n${
        response.status !== 0 ? `Status: ${response.status}\n` : ""
      }${response.json.message ? `Message: ${response.json.message}` : ""}`
    );
  }
};
